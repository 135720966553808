<template>
  <div>
    <template-header />
    <template-hero />
    <template-lorem1 />
    <template-featured-products :isFrontPage='true' />
    <template-lorem2 />
    <template-contact-form />
    <template-footer />
  </div>
</template>

<script>
import TemplateHeader from '@/components/common/TemplateHeader'
import TemplateHero from '@/components/frontPage/TemplateHero'
import TemplateFeaturedProducts from '@/components/common/TemplateFeaturedProducts'
import TemplateContactForm from '@/components/common/TemplateContactForm'
import TemplateFooter from '@/components/common/TemplateFooter'
import TemplateLorem1 from '@/components/frontPage/TemplateLorem1'
import TemplateLorem2 from '@/components/frontPage/TemplateLorem2'

export default {
  components: {
    TemplateHeader,
    TemplateHero,
    TemplateFeaturedProducts,
    TemplateContactForm,
    TemplateFooter,
    TemplateLorem1,
    TemplateLorem2
  }
}
</script>
