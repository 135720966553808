<template>
  <vs-accordion :active="currentIndex" :is-box="true" :level="4" is-compact>
    <vs-accordion-item
      @change="change"
      v-for="(n, index) in [1, 2, 3]"
      :key="index"
    >
      <template slot="accordion-trigger">
        {{ getFaqQuestion(index) }}
      </template>

      <div>
        {{ getFaqAnswer(index) }}
      </div>
    </vs-accordion-item>
  </vs-accordion>
</template>

<script>
import { VsAccordion, VsAccordionItem } from '@vuesimple/vs-accordion'

export default {
  props: ['faqs'],
  components: {
    VsAccordion,
    VsAccordionItem,
  },
  data() {
    return {
      currentIndex: 0,
      currentSingleIndex: -1,
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    setIndex() {
      this.currentIndex = this.currentIndex === 1 ? 0 : 1;
    },

    closeAll() {
      this.currentIndex = -1;
    },

    change(e) {
      this.currentIndex = e.index;
    },

    log(e) {
      console.log(e);
    },
    getFaqQuestion(index) {
      return this.faqs[index].question
    },
    getFaqAnswer(index) {
      return this.faqs[index].answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common/variables.scss';

:deep(.vs-accordion__item) {
  border: none !important;
  margin-bottom: 1px !important;

  .vs-accordion__trigger {
    background: $primary;
    color: white;

    .vs-accordion--icon {
      color: white;
    }
  }

  .vs-accordion__content {
    margin-top: 1px;
    border: 1px solid $light-grey;

    .vs-accordion__content-wrapper {
      padding: 10px 10px 13px;

      div {
        color: $secondary;
        font-size: 15px;
      }
    }
  }
}
</style>
