<template>
  <div class="container">
    <div class="product">
      <div class="product-main">
        <div class="product-image">
          <img class="image-border" src="@/assets/images/product-detail-image.jpg" />
        </div>
        <div class="product-content">
          <h1>
            SKU: {{ sku }}
            <br>
            {{ name }}
          </h1>
          <div class="price">
            {{ price }}
          </div>
          <!-- <p class="description">
            Developed and manufactured at Life Diagnostics, Inc. Purified from serum free media using hydroxyapatite chromatography at neutral pH. AGP-08-4F57...
          </p> -->
          <div class="product-size">
            <cool-select
              ref="input"
              v-model="size"
              :items="sizeValues"
            />
          </div>
          <div class="product-operations">
            <template-product-quantity />
            <div class="add-to-cart-and-like">
              <a href="#" class="button">Add to cart</a>
              <div class="like">
                <img src="@/assets/images/heart.svg" />
              </div>
            </div>
          </div>
          <!-- <template-payment-logos /> -->
        </div>
      </div> <!-- /product -->

      <div class="product-additional-details">
        <ul class="tabs">
          <li class="active">Description</li>
          <li>Specifications</li>
        </ul>
        <p>
          {{ description }}
        </p>
      </div> <!-- /product-additional-details -->
    </div> <!-- /container -->
  </div> <!-- /product-details -->
</template>

<script>
import TemplateProductQuantity from '@/components/common/TemplateProductQuantity'
// import TemplatePaymentLogos from '@/components/common/TemplatePaymentLogos'
import { CoolSelect } from 'vue-cool-select'

export default {
  components: {
    TemplateProductQuantity,
    // TemplatePaymentLogos,
    CoolSelect
  },
  data() {
    return {
      sku: 'ABCD1234',
      name: "Anti-cat alpha-1-acid glycoprotein, AGP-08-4F57",
      price: '$145.00 – $600.00',
      size: '',
      description: "Developed and manufactured at Life Diagnostics, Inc. Purified from serum free media using hydroxyapatite chromatography at neutral pH. AGP-08-4F57 can be used as capture antibody with monoclonals AGP-08-11D2 or AGP-08-11B7 as detection antibodies.",
      specifications: ""
    }
  },
  computed: {
    sizeValues() {
      return [
        "45MG",
        "10MG",
        "1MG",
      ];
    }
  },
  mounted() {
    this.size = this.sizeValues[0]
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common/mixins.scss';
@import '@/assets/styles/common/variables.scss';

:deep(.IZ-select) {
  width: 390px;

  @media only screen and (max-width: 960px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 382px;
  }

  @media only screen and (max-width: 540px) {
    width: 100%;
  }

  input {
    @include select-arrow;
    color: $black !important;
  }
}
</style>